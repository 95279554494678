@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #000300;
}



.formulario {
	padding: 20px;
}

.formulario > div {
	margin-bottom: 20px;
}

.formulario label {
	display: block;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 5px;
	color: #ffffff;
}

.formulario input,
.formulario textarea {
	font-family: 'Open Sans', sans-serif;
	width: 100%;
	border-radius: 5px;
	border: 2px solid #e2e2e2;
	font-size: 18px;
	padding: 10px;
	margin-bottom: 5px;
	color: #1f1f1f;
}

.formulario input:focus {
	outline: none;
	border: 2px solid #0085FF;
}

.formulario textarea:focus {
	outline: none;
	border: 2px solid #0085FF;
}
.formulario .error {
	color: #e92b2d;
	font-weight: 600;
	font-size: 12px;
}

.formulario button[type="submit"] {
	display: block;
	background: #0085FF;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	border: none;
	cursor: pointer;
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	color: #fff;
	font-size: 18px;
	transition: .3s ease all;
}

.formulario button[type="submit"]:hover {
	background: #0051ff;
}

.formulario .exito {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	padding-top: 20px;
	color: #02a802;
}

.formulario .errorMensaje {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	padding-top: 20px;
	color: #ad0707;
}

.formulario input[type="radio"] {
	width: auto;
}

/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #00BCD4 rgb(82, 81, 80);
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
	width: 12px;
  }
  
  *::-webkit-scrollbar-track {
	background: rgb(112, 112, 112);
	border-radius: 20px;
  }
  
  *::-webkit-scrollbar-thumb {
	border-radius: 20px;
  }

  /* Cambiamos el fondo cuando esté en active */
*:hover::-webkit-scrollbar-thumb {
    background-color: #00BCD4 !important;
}
  /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
*::-webkit-scrollbar-thumb:hover {
    background: #00BCD4;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

